/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Reset - CRITICAL
# Normalize
# Accesibility
# Fonts
# Typography
# Alignments
# Links
# Forms
# Menu
# Widget
# Footer
# Content
# Media Queries


--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Reset - CRITICAL
--------------------------------------------------------------*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  font-size: 55%;
  /* Corrects text resizing oddly in IE6/7 when body font-size is set using em units http://clagnut.com/blog/348/#c790 */
  overflow-y: scroll;
  /* Keeps page centered in all browsers regardless of content height */
  -webkit-text-size-adjust: 100%;
  /* Prevents iOS text size adjust after orientation change, without disabling user zoom */
  -ms-text-size-adjust: 100%;
  /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
  font-family: sans-serif;
}

*,
*:before,
*:after {
  /* apply a natural box layout model to all elements; see http://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
  -webkit-box-sizing: border-box;
  /* Not needed for modern webkit but still used by Blackberry Browser 7.0; see http://caniuse.com/#search=box-sizing */
  -moz-box-sizing: border-box;
  /* Still needed for Firefox 28; see http://caniuse.com/#search=box-sizing */
  box-sizing: border-box;
}

body {
  background: white;
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  /* tables still need 'cellbaseline="0"' in the markup */
  border-collapse: separate;
  border-baseline: 0;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote,
q {
  quotes: "" "";
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

a img {
  border: 0;
}

::selection {
  background: #ff9000;
  color: white;
}

::-moz-selection {
  background: #ff9000;
  color: white;
}

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
  font-family: Montserrat, sans-serif;
  font-size: 10px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust:     100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-size: 16px;
  color: #292a3f;
  background-color: #fff;
  line-height: 1.5;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

article{
  margin-bottom: 25px;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

small {
  font-size: 80%;
}


img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.6em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

textarea:focus, input:focus{
  outline: none;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #eee;
  margin: 0 0 1.6em;
  width: 100%;
}

td,
th {
  padding: 0;
}

label {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
}

blockquote,
q {
	quotes: "" "";
}

ul,
ol {
	margin: 0 0 1.6em 2.4em;
	padding: 0;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 2.4em;
}

img {
	height: auto;
	max-width: 100%;
}

th,
td {
	padding: .8em 5px;
}
tr:nth-of-type(2n) {
	background: #f9f9f9;
}


/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {	
	background-color: #f1f1f1;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	background-clip: padding-box;
  	/* stops bg color from leaking outside the border: */
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #ff9000;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 20px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}


/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}


/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
      url('~fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
      url('~fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'),
      url('~fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

body,
button,
input,
select,
optgroup,
textarea {
  color: #444;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: #292a3f;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
  line-height: 110%;
}

h1 {
  font-size: 25.6px;
  font-size: 2.56rem;
  font-weight: 700;
  line-height: 1.17188;
}

h1.site-title,
a.site-title {
  font-weight: 700 !important;
  font-size: 30px;
  color: #292a3f;
  text-transform: uppercase;
}

h1.entry-title {
  font-weight: 700 !important;
  font-size: 38px;
  color: #ff9000;
  text-transform: none !important;
  margin: 0;
}

h1.page-title {
  color: #777777;
}

h1.entry-title a,
h1.entry-title a:visited {
  color: #292a3f;
}

h1.entry-title a:hover {
  color: #ff9000;
  text-decoration: none;
}

h1.widget-title {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 20px 0;
  text-transform: uppercase;
}

h1.site-title a:hover {
  color: #a00101;
}

h2 {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
}

h2.site-description {
  font-size: 15px;
  font-weight: normal;
  opacity: 0.8;
  line-height: 120%;
}

h2.comments-title {
  font-size: 25.6px;
  font-size: 2.56rem;
  padding-bottom: 40px;
}

h3 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.875;
}

h4 {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.14286;
}

h5 {
  font-size: 12.5px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.4;
}

h6 {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2.5;
}

p {
  margin-bottom: 1.5em;
}

b,
strong {
  font-weight: bold;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 1.5em 0;
}

.format-quote .entry-content,
blockquote {
  border-top: 2px solid #eeeeee;
  border-bottom: 2px solid #eeeeee;
  color: #777777;
  font-style: italic;
  padding: 40px 0 20px 0;
}

.format-quote .entry-content blockquote {
  border-top: none;
  border-bottom: none;
  margin: 0;
  padding: 0;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

sup,
sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

.widget {
  font-size: 14px;
  font-size: 1.4rem;
}

a.site-title:hover {
  text-decoration: none;
  color: #292a3f !important;
}

.srp-widget-title {
  font-size: larger;
}

.srp-post-title {
  font-size: small;
  line-height: 22px;
}

.srp-post-date {
  font-size: 12px;
  opacity: 0.8;
}

/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/

a {
  color: #ff9000;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-decoration: none;
  font-weight: bold;
}

a:hover,
a:focus,
a:active {
  color: #ff9000;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

.entry-summary a,
.entry-summary a:hover,
.entry-content a,
.entry-content a:hover {
  text-decoration: underline;
}

.entry-summary a:hover,
.entry-content a:hover {
  color: #a00101;
}


/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right;
}

img.alignright {
  margin-left: 0.75em;
}

img.alignleft {
  margin-right: 0.75em;
}

img.aligncenter {
  margin: 0 auto;
}


/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/

*:focus {
  outline: none;
}

input::-webkit-input-placeholder {
  opacity: 0.6;
  font-weight: 500; 
  color:#292a3f;
}
input:-ms-input-placeholder {
  opacity: 0.6;
  font-weight: 500; 
  color:#292a3f;
}
input::placeholder {
  opacity: 0.6;
  font-weight: 500; 
  color:#292a3f;
}
textarea::-webkit-input-placeholder {
  opacity: 0.6;
  font-weight: 500; 
  color:#292a3f;
}
textarea:-ms-input-placeholder {
  opacity: 0.6;
  font-weight: 500; 
  color:#292a3f;
}
textarea::placeholder {
  opacity: 0.6;
  color:#292a3f !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.button {
  border: 0;
  background-color: #eee;
  font-family: Montserrat, Tahoma, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  padding: 15px 1.6em;
  text-transform: uppercase;
  transition: 0.2s;
}

.button.button__secondary {
  background: white;
  border: 2px solid #ff9000;
  color: #ff9000;
  border: none;
}

.button.button--smaller {
  padding: 15px !important;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
.button:hover {
  background-color: #ccd4db;
}

button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active, 
input[type="submit"]:active,
.button:active {
  background-color: #ccd4db;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea,
select {
  font-family: "Montserrat", Tahoma, sans-serif;
  padding: 11px;
  background: #eee;
  max-width: 100%;
  transition: 0.1s;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
  border-color: #ccc;
  color: #111;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

input[type="checkbox"], input[type="radio"] {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-top: -3px;
  opacity: 0;
  z-index: 1;
  position: relative;
  cursor: pointer;
  margin-right: 18px;
}

.contact-form input[type="checkbox"] {
  margin-right: 10px !important;
}
span.wpcf7-form-control-wrap {
  display: block;
  margin-top: 14px;
  margin-bottom: 14px;
}
label span.wpcf7-form-control-wrap {
  display: inline-block;
}
span.wpcf7-form-control-wrap.acceptance-189 {
  display: inline-block;
}
input.wpcf7-not-valid, textarea.wpcf7-not-valid, span.wpcf7-not-valid span.wpcf7-list-item:after, span.wpcf7-list-item.wpcf7-not-valid:after {
  border: 2px solid #ce3b3b !important;
}
span.wpcf7-list-item {
  position: relative;
  margin: 0;
}

p.comment-form-cookies-consent:after,
.mc4wp-form-fields p:not(#subscribe-email):after,
p.comment-subscription-form:after,
span.wpcf7-list-item:after {
  content: '';
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  top: 0;
  left: 0;
  border-radius: 100%;
  border: 2px solid #ff9000;
  cursor: pointer;
  margin-right: 18px;
}

p.comment-form-cookies-consent:before,
.mc4wp-form-fields p:not(#subscribe-email):before,
p.comment-subscription-form:before,
span.wpcf7-list-item:before {
  content: '';
  display: block;
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  top: 0.4rem;
  left: 0.4rem;
  border-radius: 100%;
  background-color: #ff9000;
  opacity: 0;
}

p.comment-form-cookies-consent.checked:before,
.mc4wp-form-fields p:not(#subscribe-email).checked:before,
p.comment-subscription-form.checked:before,
span.wpcf7-list-item label.checked:before,
span.wpcf7-list-item.checked:before {
  opacity: 1;
}
.wpcf7-form-control-wrap .wpcf7-form-control:not(.wpcf7-acceptance) span {
  display: flex;
  margin-right: 10px;
  padding-left: 0;
}
.wpcf7-form-control-wrap .wpcf7-form-control:not(.wpcf7-acceptance) {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}
.wpcf7-list-item {
  margin-right: 10px;
  margin-left: 10px;
}
.wpcf7-list-item-label {
  font-weight: 700;
  font-size: 13px;
}

.wpcf7-form-control-wrap label {
  display: flex;
  margin-bottom: 10px;
}
input[type="text"],
input[type="number"], 
input[type="email"], 
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"], 
textarea,
select {
  background-color: #fff !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px;
  font-weight: 700;
  border-radius: 30px !important;
  border: 2px solid transparent;
  box-shadow: 0 1px 5px 0 rgba(5,5,5,0.20);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
}

textarea {
  width: 100%;
  border-radius: 12px !important;
}

.form-push-down {
  margin-top: 40px;
  display: inline-block;
  width: 100%;
}

input.space-bottom, 
textarea.space-bottom,
select.space-bottom,
.form-group {
  margin-bottom: 15px;
}

input.full-width, 
textarea.full-width,
select.full-width {
  width: 100%;
}

.radio-button {
  display: inline-block;
}

.radio-button label {
  display: block;
  line-height: 22px;
}

.radio-button span {
  display: inline-block;
}

.radio-button .wpcf7-list-item {
  display: flex;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 10px;
  padding-right: 4rem;
}

input[type="button"],
input[type="submit"],
.button {
  background-color: #ff9000;
  color: #fff;
  font-weight: 700;
  padding: 20px 30px !important;
  border-radius: 30px !important;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 0px 0px 0px;
  transition: all 200ms ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover {
  background-color: #ff9000;
  box-shadow: 0 3px 6px 0 rgba(5,5,5,0.20);
  color: #fff;
}

.button.button__secondary:hover {
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(5,5,5,0.20);
  color: #ff9000;
}

.search-form {
	position: relative;
}

.widget-areas .search-form input {
  width: 100%;
}


/*--------------------------------------------------------------
## Menu
--------------------------------------------------------------*/

a {
  color: #ff9000;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-decoration: none;
  font-weight: bold;
}

.site-branding {
  margin: 0;
  padding: 20px;
  background: #f7f7f7;
}

.main-navigation {
  background: #f7f7f7;
  clear: both;
  font-weight: 700;
  padding: 0;
}
.main-navigation ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.main-navigation ul ul {
  background: #ff9000;
  color: white;
  display: none;
  margin: 0;
  padding: 0;
}
.main-navigation ul ul li:first-child a {
  padding: 10px 20px 10px 40px;
}
.main-navigation ul ul li:last-child a {
  padding: 10px 20px 10px 40px;
}
.main-navigation ul ul ul li:first-child a {
  padding: 10px 20px 10px 60px;
}
.main-navigation ul ul ul li:last-child a {
  padding: 10px 20px 10px 60px;
}
.main-navigation ul ul ul ul li:first-child a {
  padding: 10px 20px 10px 80px;
}
.main-navigation ul ul ul ul li:last-child a {
  padding: 10px 20px 10px 80px;
}
.main-navigation ul ul li a {
  opacity: 0.7;
  padding-left: 40px;
}
.main-navigation ul ul li a:hover {
  background: #a00101;
  opacity: 1;
}
.main-navigation ul ul ul li a {
  padding-left: 60px;
}
.main-navigation ul ul ul ul li a {
  padding-left: 80px;
}
.main-navigation ul ul {
  top: 0;
}
.main-navigation li a {
  	background: #f7f7f7;
  	padding: 10px 20px;
  	position: relative;
    transform: perspective(1px) translateZ(0);
  	box-shadow: 0 0 1px rgba(0, 0, 0, 0);

}
.main-navigation li a:hover {
	transform: translateX(8px);
   	background: #f7f7f7;
   	color: #ff9000;
   	text-decoration:none;
}
.main-navigation a,
.main-navigation a:visited {
  color: white;
  display: block;
  opacity: 1;
  text-transform: uppercase;
}
.main-navigation a:hover {
  color: white;
  opacity: 1;
}

.main-navigation ul ul.sub-on {
  display: block;
}

.main-navigation .current_page_item a,
.main-navigation .current-menu-item a {
  color: #eeeeee;
}

.menu-lateral-container {
  padding: 20px 10px 20px 0;
}

.main-navigation a,
.main-navigation a:visited {
  color: #ff9000;
  width: 98%;
}

.main-navigation .current_page_item a,
.main-navigation .current-menu-item a {
  color: #292a3f;
}

.site-main [class*="navigation"] {
  margin: 0;
  overflow: hidden;
}

h1.site-title, a.site-title{
  font-weight: 700!important;
  font-size: 30px;
  color: #292a3f;
  text-transform: uppercase;
}

[class*="navigation"] .nav-previous {
  float: left;
  width: 50%;
}

[class*="navigation"] .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

#masthead,
#secondary {
  display: none;
}

.nav-links {
  overflow: hidden;
  padding: 20px;
}

.nav-previous a,
.nav-previous a:visited,
.nav-next a,
.nav-next a:visited {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 5px;
}

#social-links-wrapper {
  clear: both;
  display: block;
  padding: 20px;
}

.social-links ul a:before,
#mobile-link:before,
.showsub-toggle,
#widget-link {
  -webkit-font-smoothing: antialiased;
  font-family: "Genericons";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
  vertical-align: text-bottom;
}

.showsub-toggle {
  content: "";
  display: block;
  height: 41px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 40px;
}

.showsub-toggle {
  opacity: 0.5;
}

.showsub-toggle:after {
  color: #eeeeee;
  content: "\f502";
  font-size: 16px;
  height: 16px;
  position: absolute;
  right: 11px;
  top: 14px;
  width: 16px;
}

.showsub-toggle:hover,
.showsub-toggle:focus,
.showsub-toggle.sub-on {
  opacity: 1;
}

.showsub-toggle:hover:after,
.showsub-toggle:focus:after {
  color: white;
}

.showsub-toggle.sub-on:after {
  content: "\f500";
  top: 6px;
}


#mobile-panel {
  background: #292a3f;
  overflow: hidden;
  padding: 0 0 0.75em 0;
  width: 100%;
}

.widget_search .search-submit {
  display: none;
}

#widget-link {
  color: white;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 45px;
  padding: 10px;
  width: 50px;
}

#widget-link:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("~images/svg/search.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#mobile-link:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("~images/svg/menu.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#mobile-link {
  color: white;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 45px;
  padding: 10px;
  width: 50px;
  padding-top: 15px;
}

#mobile-panel h1.site-title {
  	clear: none;
  	float: initial;
  	font-size: 18px;
  	font-size: 1.8rem;
  	margin: 0;
  	padding: 15px 10px 0 10px;
	text-align: center;
}

#mobile-panel h1.site-title a {
  color: white;
}

#mobile-block {
  border-top: 1px solid #fff;
  display: none;
  overflow: hidden;
}

#mobile-block.active {
  display: block;
}

#mobile-block {
  top: 0;
}

.menu-lateral-container {
    padding: 20px 10px 20px 0;
}

.main-navigation a, .main-navigation a:visited{
  color: #ff9000;
  width: 98%;
}

.main-navigation .current_page_item a, .main-navigation .current-menu-item a{
  color: #292a3f;
}


/*--------------------------------------------------------------
# Widget
--------------------------------------------------------------*/

.textwidget > a:first-of-type {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.textwidget {
  text-align: center;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

footer.main {
  padding: 80px 5%!important;
  border-top: 1px solid #efefef;
}

.footer-title {
  display: block;
  color: #292a3f;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer-tlf {
  display: block;
  font-size: 18px;
  color: #464646;
  font-weight: 700;
}

.time-info {
  display: block;
  color: #464646;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer-mail {
  display: block;
  color: #ff9000;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
}

.footer-adress {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: normal;
}

.footer-link {
  display: block;
  color: #24263d;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 6px;
}

.rrss-block {
  border: 1px solid #f5f5f5;
  padding: 20px 15px;
}

.rrss-block__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rrss-block__links a {
  font-size: 12px;
  color: #24263d!important;
}

div .link-rrss ~ .link-rrss {
  padding: 0 0 0 25px;
}

.copy {
  display: block;
  text-align: center;
  font-size: 12px;
  padding: 20px 0;
  font-weight: normal;
  background-color: #fafafa;
}

/*--------------------------------------------------------------
# Cookie Banner
--------------------------------------------------------------*/

#cookie-law {
  bottom: 0;
  position: fixed;
  display: block;
  text-align: left;
  padding: 2rem 0;
  font-size: 16px;
  line-height: 16px;
  color: white;
  width: 100%;
  background: rgba(0,0,0, 0.7);
  z-index: 2000000001;
  transition: bottom 350ms ease-in-out;
}

#cookie-law .cookie__wrap {
  width: 88%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

#cookie-law p {
  margin: 0;
  line-height: 2rem;
}

#cookie-law p a {
  color: white;
  text-decoration: underline;
}

#cookie-law h5 {
  display: inline-block;
  margin: 0;
  line-height: 16px;
  color: white;
  margin-bottom: 1rem;
}

#cookie-law .button {
  margin: 0;
  width: auto;
  padding: 14px 20px !important;
}

#cookie-law .cookie__text {
  max-width: 70%;
}

#cookie-law .cookie__button {
  margin-left: auto;
}

#cookie-law.not-visible {
  bottom: -30vh;
  pointer-events: none;
}


/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/

#content {
  padding-bottom: 50px;
}

.post,
.page {
  margin: 0 0 1.5em;
}


/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/


@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .entry-meta {
    padding: 40px 0;
  }

  footer.entry-meta {
    margin-bottom: 80px;
  }
}


@media only screen and (min-width: 64.063em) {
  .entry-meta {
    padding: 0px;
    border-bottom: 0px;
    font-size: 1.4rem;
    font-weight: normal;
    padding: 40px 0 20px;
  }

  footer.entry-meta {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 90.063em) {
  .widget,
  .site-branding {
    padding: 70px 40px;
  }

  .entry-header,
  .page-title {
    padding: 70px 0 0 0;
  }

  .main-navigation li a {
    padding: 10px 40px;
  }

  .main-navigation li:first-child a {
    padding: 20px 40px 10px 40px;
  }

  .main-navigation li:last-child a {
    padding: 10px 40px 20px 40px;
  }

  .main-navigation ul ul li a {
    opacity: 0.7;
    padding-left: 40px;
  }

  .main-navigation ul ul li:first-child a {
    padding: 10px 20px 10px 60px;
  }

  .main-navigation ul ul li:last-child a {
    padding: 10px 20px 10px 60px;
  }

  .main-navigation ul ul ul li a {
    padding-left: 80px;
  }

  .main-navigation ul ul ul li:first-child a {
    padding: 10px 20px 10px 80px;
  }

  .main-navigation ul ul ul li:last-child a {
    padding: 10px 20px 10px 80px;
  }

  .main-navigation ul ul ul ul li a {
    padding-left: 100px;
  }

  .main-navigation ul ul ul ul li:first-child a {
    padding: 10px 20px 10px 100px;
  }

  .main-navigation ul ul ul ul li:last-child a {
    padding: 10px 20px 10px 100px;
  }

  #social-links-wrapper {
    padding: 40px;
  }

  #page {
    width: 70%;
  }

  body.secondary-sidebar #page {
    width: 100%;
  }
}


@media only screen and (max-width: 47.938em) {
  #mobile-panel h1.site-title {
    text-align: left;
  }

  #widget-link {
    padding-top: 15px;
  }

  h1.entry-title {
    font-size: 1.875em;
  }
  .main-navigation li a {
    font-weight: 400;
    font-size: 13px;
  }
  .main-navigation .current_page_item a,
  .main-navigation .current-menu-item a {
    font-weight: 700;
  }
  .main-navigation ul {
    padding: 20px 0;
  }
}

/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
@media only screen and (min-width: 64.063em) {
  
  body.secondary-sidebar #masthead:before {
    right: 72%;
  }

  body.secondary-sidebar #masthead, body.secondary-sidebar #masthead:before {
    width: 28%;
  }

  body.secondary-sidebar #content, body.secondary-sidebar #content:before {
    width: 72%;
  }  


  #mobile-panel {
    display: none;
  }

  #main,
  .page-header {
    margin: 0;
    padding: 0 40px 60px;
  }

  h1 {
    font-size: 40.96px;
    font-size: 4.096rem;
  }

  h2 {
    font-size: 32px;
    font-size: 3.2rem;
  }

  h3 {
    font-size: 25.6px;
    font-size: 2.56rem;
  }

  h4 {
    font-size: 20px;
    font-size: 2rem;
  }

  h5 {
    font-size: 16px;
    font-size: 1.6rem;
  }

  h6 {
    font-size: 12.5px;
    font-size: 1.25rem;
  }

  html {
    font-size: 62.5%;
    /* Corrects text resizing oddly in IE6/7 when body font-size is set using em units http://clagnut.com/blog/348/#c790 */
  }

  .entry-meta {
    padding:0px;
    border-bottom: 0px; 
    font-size: 1.4rem;
    font-weight: normal;
    padding: 40px 0 20px;
  }

  .entry-header,
  .page-title {
    padding: 40px 0 0 0;
  }

  footer.entry-meta {
    margin-bottom: 0px;
  }

  #colophon {
    margin: 0 40px;
    padding: 80px 0;
  }

  .nav-links {
    overflow: hidden;
    padding: 40px;
  }

  #page {
    position: relative;
  }

  #page:before,
  #page:after {
    content: " ";
    display: table;
  }

  #page:after {
    clear: both;
  }

  #content, #content:before {
    width: 75%;
  }

  #content {
    float: left;
  }

  #content:before {
    background-color: white;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  #secondary, #secondary:before {
    width: 20%;
  }

  #secondary {
    display: block;
    float: right;
    font-size: 90%;
  }

  #secondary:before {
    background-color: #F7F7F7;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  #masthead,
  #masthead:before {
    width: 25%;
  }



  #masthead {
    display: block;
    float: left;
    font-size: 90%;
  }

  #masthead:before {
    background: #fafafa;
    bottom: 0;
    content: '';
    position: absolute;
    right: 75%;
    top: 0;
    z-index: -1;
  }

  #page {
    width: 100%;
  }

  body.secondary-sidebar #page {
    width: 100%;
  }

}

@media (max-width:991px) {
  .rrss-block__links {
    margin-bottom: 25px;
    justify-content: center;
  }
  .rrss-block__social {
    text-align: center !important;
    margin-bottom: 10px
  }
  .rrss-block__links a {
    margin: 0 14px;
  }
}

@media only screen and (max-width: 767px) {
  .rrss-block__links {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-block {
    margin-top: 20px;
  }
  .secure-logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .rrss-block__links a {
    display: block;
    margin-bottom: 15px;
    text-align: center;
  }

  #cookie-law .cookie__wrap {
    flex-direction: column;
    align-items: flex-end;
  }
  
  #cookie-law p {
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: justify;
  }
  
  #cookie-law h5 {
    display: none;
  }
  
  #cookie-law .button {
    display: block;
  }
  
  #cookie-law .cookie__text {
    max-width: 100%;
    margin-bottom: 1.2rem;
  }
  
  #cookie-law .cookie__button {
    margin-left: 0;
  }
}

@media only screen and (max-width: 600px){
  footer {
      padding: 40px 15px!important;
      border-top: 1px solid #efefef;
  }

  .footer-block {
      margin: 20px 0;
      padding: 0px;
  }

  .footer-link {
      margin-left: 10px;
  }

  .rrss-block__links, .rrss-block__links a{
      display:block !important;
  }
}